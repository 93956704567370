import React from 'react';
import "../../../styles/styles.css";
import "../../../styles/dop_styles.css";
import Header from '../../header/header';
import Footer from '../../footer/footer';
import cl from './privacy_policy.module.css';

const Privacy_policy = () => {
    return (
        <div className='wrapper'>
            <Header />
            <main>
                <div className='block main'>
                    <div className='inner'>
                        <div className="content nositebar ">
                            <div className="breadcrumb">
                                <span><a href="/">Главная</a> / </span>
                                <span>Политика конфиденциальности</span>
                            </div>
                            <h1 className="shop-title">Политика конфиденциальности</h1>
                            <div className={cl.page_text}>
                                {/* <ol style={{margin: 0, padding: 0}}>
                                    <li>
                                        <p style={{textAlign:"center"}}>ОБЩИЕ ПОЛОЖЕНИЯ</p> */}
                                        {/* <ol>
                                            <li>Настоящее Положение об обработке персональных данных (далее – Положение, настоящее Положение) разработано <strong><em>название компании</em></strong> (далее также – Оператор) и применяется в соответствии с п. 2 ч. 1 ст. 18.1. Федерального закона от 27.07.2006 №&nbsp;152-ФЗ «О персональных данных».
                                                Настоящее Положение определяет политику Оператора в отношении обработки персональных данных.
                                                Все вопросы, связанные с обработкой персональных данных, не урегулированные настоящим Положением, разрешаются в соответствии с действующим законодательством Российской Федерации в области персональных данных.
                                                Настоящее Положение и изменения к нему утверждаются руководителем Оператора и вводятся приказом Оператора.</li>
                                            <li>В соответствии с п. 1 ст. 3 Федерального закона от 27.07.2006 №&nbsp;152-ФЗ «О&nbsp;персональных данных» под персональными данными клиентов, физических лиц понимается любая информация, относящаяся к прямо или косвенно определённому или определяемому на основании такой информации клиенту, физическому лицу (далее – персональные данные).</li>
                                            <li><strong><em>Название компании</em></strong> является оператором, организующим и (или) осуществляющим обработку персональных данных, а также определяющим цели и содержание обработки персональных данных.</li>
                                            <li>Целью обработки персональных данных является:
                                                <ul>
                                                    <li>обеспечение защиты прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну;</li>
                                                    <li>оказание Оператором физическим и юридическим лицам услуг, связанных с хозяйственной деятельностью Оператора, включая контакты Оператора с такими лицами, в том числе по электронной почте, по телефону, по адресу, предоставленным соответствующим лицом;</li>
                                                    <li>направление консультаций, ответов обратившимся лицам с помощью средств связи и указанных ими контрактных данных;</li>
                                                    <li>продвижение товаров, работ, услуг Оператора на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи (допускается только при условии предварительного согласия субъекта персональных данных).</li>
                                                </ul>
                                            </li>
                                            <li>Обработка организована Оператором на принципах:
                                                <ul>
                                                    <li>законности целей и способов обработки персональных данных, добросовестности и справедливости в деятельности Оператора;</li>
                                                    <li>достоверности персональных данных, их достаточности для целей обработки, недопустимости обработки персональных данных, избыточных по отношению к целям, заявленным при сборе персональных данных;</li>
                                                    <li>обработки только персональных данных, которые отвечают целям их обработки;</li>
                                                    <li>соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки. Обрабатываемые персональные данные не должны быть избыточными по отношению к заявленным целям их обработки;</li>
                                                    <li>недопустимости объединения баз данных, содержащих персональные данные, обработка которых осуществляется в целях, не совместимых между собой;</li>
                                                    <li>обеспечения точности персональных данных, их достаточности, а в необходимых случаях и актуальности по отношению к целям обработки персональных данных. Оператор принимает необходимые меры либо обеспечивает их принятие по удалению или уточнению неполных или неточных данных;</li>
                                                    <li>хранения персональных данных в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.</li>
                                                </ul>
                                            </li>
                                            <li>Обработка персональных данных осуществляется с соблюдением принципов и правил, предусмотренных Федеральным законом от 27.07.2006 №&nbsp;152-ФЗ «О персональных данных» и настоящим Положением.</li>
                                            <li>Персональные данные обрабатываются с использованием и без использования средств автоматизации.</li>
                                            <li>В соответствии с поставленными целями и задачами Оператор до начала обработки персональных данных назначает ответственного за организацию обработки персональных данных.
                                                <ol>
                                                    <li>Ответственный за организацию обработки персональных данных получает указания непосредственно от исполнительного органа Оператора и подотчетен ему.</li>
                                                    <li>Ответственный за организацию обработки персональных данных вправе оформлять и подписывать уведомление, предусмотренное ч. 1 и 3 ст. 22 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
                                                </ol>
                                            </li>
                                            <li>Сотрудники Оператора, непосредственно осуществляющие обработку персональных данных, должны быть ознакомлены до начала работы с положениями законодательства Российской Федерации о персональных данных, в том числе с требованиями к защите персональных данных, документами, определяющими политику Оператора в отношении обработки персональных данных, локальными актами по вопросам обработки персональных данных, с данным Положением и изменениями к нему.</li>
                                            <li>При обработке персональных данных Оператор применяет правовые, организационные и технические меры по обеспечению безопасности персональных данных в соответствии со ст. 19 Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных».</li>
                                            <li>При осуществлении сбора персональных данных с использованием информационно-телекоммуникационных сетей Оператор обязан опубликовать в соответствующей информационно-телекоммуникационной сети документ, определяющий его политику в отношении обработки персональных данных, и сведения о реализуемых требованиях к защите персональных данных, а также обеспечить возможность доступа к указанному документу с использованием средств соответствующей информационно-телекоммуникационной сети.</li>
                                            <li>Условия обработки персональных данных Оператором. Обработка персональных данных допускается в следующих случаях:
                                                <ul>
                                                    <li>обработка персональных данных осуществляется с согласия субъекта персональных данных на обработку его персональных данных;</li>
                                                    <li>обработка персональных данных необходима для достижения целей, предусмотренных международным договором Российской Федерации или законом, для осуществления и выполнения возложенных законодательством Российской Федерации на Оператора функций, полномочий и обязанностей;</li>
                                                    <li>обработка персональных данных необходима для исполнения договора, стороной которого либо выгодоприобретателем или поручителем по которому является субъект персональных данных, в том числе в случае реализации Оператором своего права на уступку прав (требований) по такому договору, а также для заключения договора по инициативе субъекта персональных данных или договора, по которому субъект персональных данных будет являться выгодоприобретателем или поручителем;</li>
                                                    <li>обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов субъекта персональных данных, если получение согласия субъекта персональных данных невозможно;</li>
                                                    <li>обработка персональных данных необходима для осуществления прав и законных интересов Оператора или третьих лиц либо для достижения общественно значимых целей при условии, что при этом не нарушаются права и свободы субъекта персональных данных;</li>
                                                    <li>обработка персональных данных осуществляется в статистических или иных исследовательских целях, за исключением целей, указанных в ст. 15 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных», при условии обязательного обезличивания персональных данных;</li>
                                                    <li>осуществляется обработка персональных данных, доступ неограниченного круга лиц к которым предоставлен субъектом персональных данных либо по его просьбе;</li>
                                                    <li>осуществляется обработка персональных данных, подлежащих опубликованию или обязательному раскрытию в соответствии с федеральным законом.</li>
                                                </ul>
                                            </li>
                                            <li>Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели их обработки, и они подлежат уничтожению по достижении целей обработки или в случае утраты необходимости в их достижении в порядке, предусмотренном Положением о хранении персональных данных у Оператора.</li>
                                            <li>Персональные данные, которые обрабатываются в информационных системах, подлежат защите от несанкционированного доступа и копирования. Безопасность персональных данных при их обработке в информационных системах обеспечивается с помощью системы защиты персональных данных, включающей организационные меры и средства защиты информации. Технические и программные средства должны удовлетворять устанавливаемым в соответствии с законодательством Российской Федерации требованиям, обеспечивающим защиту информации.</li>
                                            <li>Взаимодействие с федеральными органами исполнительной власти по вопросам обработки и защиты персональных данных субъектов, персональные данные которых обрабатываются Оператором, осуществляется в рамках законодательства Российской Федерации.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p style={{textAlign:"center"}}>ОБЕСПЕЧЕНИЕ ОПЕРАТОРОМ ПРАВ СУБЪЕКТА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

                                        <ol>
                                            <li>Субъекты персональных данных или их представители обладают правами, предусмотренными Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных» и другими нормативно-правовыми актами, регламентирующими обработку персональных данных.</li>
                                            <li>Оператор обеспечивает права субъектов персональных данных в порядке, установленном главами 3 и 4 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
                                            <li>Оператор обязан предоставить безвозмездно субъекту персональных данных или его представителю возможность ознакомления с персональными данными, относящимися к этому субъекту персональных данных, по месту расположения Оператора в рабочее время Оператора.</li>
                                            <li>Право субъекта персональных данных на доступ к его персональным данным может быть ограничено в соответствии с федеральными законами.</li>
                                            <li>В случае представления интересов субъекта персональных данных представителем полномочия представителя подтверждаются доверенностью, оформленной в установленном порядке.</li>
                                            <li>В случаях предоставления субъектом персональных данных письменного согласия на использование персональных данных для такого согласия достаточно простой письменной формы.</li>
                                            <li>Оператор гарантирует безопасность и конфиденциальность используемых персональных данных.</li>
                                            <li>Обработка персональных данных в целях продвижения товаров, работ, услуг на рынке путем осуществления прямых контактов с потенциальным потребителем с помощью средств связи допускается только при условии предварительного согласия субъекта персональных данных.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p style={{textAlign:"center"}}>ПОЛУЧЕНИЯ, ОБРАБОТКА, ХРАНЕНИЕ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

                                        <ol>
                                            <li>У Оператора устанавливается следующий порядок получения персональных данных:
                                                <ol>
                                                    <li>При обращении за получением услуг Оператора клиент указывает установленные соответствующими формами данные.</li>
                                                    <li>Оператор не получает и не обрабатывает персональные данные клиента о его расовой принадлежности, политических взглядах, религиозных и философских убеждениях, состоянии здоровья, интимной жизни, если законом не&nbsp;предусмотрено иное.</li>
                                                    <li>В случаях, непосредственно связанных с вопросами трудовых отношений, в соответствии со ст. 24 Конституции Российской Федерации Организация вправе получать и обрабатывать данные о частной жизни клиента только с его письменного согласия.</li>
                                                </ol>
                                            </li>
                                            <li>В случае принятия клиентом оферты, размещённой на сайте Оператора, либо заключения другого договора с Оператором обработка персональных данных клиента осуществляется для исполнения соответствующего договора, вступившего в силу вследствие принятия условий оферты клиентом либо заключения другого договора соответственно.</li>
                                            <li>Также Оператор вправе обрабатывать персональные данные клиентов, обратившихся к Оператору физических лиц только с их согласия на использование персональных данных.</li>
                                            <li>Согласие клиента на обработку персональных данных не требуется в следующих случаях:
                                                <ul>
                                                    <li>персональные данные являются общедоступными;</li>
                                                    <li>обработка персональных данных осуществляется на основании федерального закона, устанавливающего ее цель, условия получения персональных данных и круг субъектов, персональные данные которых подлежат обработке, а также определенного полномочия Организации;</li>
                                                    <li>по требованию полномочных государственных органов - в случаях, предусмотренных федеральным законом;</li>
                                                    <li>обработка персональных данных в целях исполнения договора, заключённого с Оператором;</li>
                                                    <li>обработка персональных данных осуществляется для статистических или иных научных целей при условии обязательного обезличивания персональных данных;</li>
                                                    <li>обработка персональных данных необходима для защиты жизни, здоровья или иных жизненно важных интересов клиента, если получение его согласия невозможно.</li>
                                                </ul>
                                            </li>
                                            <li>Оператор обеспечивает безопасное хранение персональных данных, в том числе:
                                                <ol>
                                                    <li>Хранение, комплектование, учет и использование содержащих персональные данные документов организуется в форме обособленного архива Оператора.</li>
                                                    <li>Хранение персональных данных должно осуществляться в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных, если срок хранения персональных данных не установлен федеральным законом, договором, стороной которого, выгодоприобретателем или поручителем по которому является субъект персональных данных. Обрабатываемые персональные данные подлежат уничтожению либо обезличиванию по достижении целей обработки или в случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным законом.</li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p style={{textAlign:"center"}}>ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

                                        <ol>
                                            <li>Персональные данные передаются с соблюдением следующих требований:
                                                <ul>
                                                    <li>запрещается сообщать персональные данные третьей стороне без письменного согласия клиента, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни, здоровью клиента, а также в других случаях, предусмотренных законами;</li>
                                                    <li>не сообщать персональные данные в коммерческих целях без письменного согласия субъекта таких данных;</li>
                                                    <li>предупредить лиц, получающих персональные данные, о том, что эти данные могут быть использованы лишь в целях, для которых они сообщены, и требовать от этих лиц подтверждения того, что это правило соблюдено;</li>
                                                    <li>разрешать доступ к персональным данным только специально уполномоченным лицам, при этом указанные лица должны иметь право получать только те персональные данные, которые необходимы для выполнения конкретных функций;</li>
                                                    <li>не запрашивать информацию о состоянии здоровья клиента, за исключением тех сведений, которые относятся к вопросу о возможности выполнения клиентом обязательств по договору с Оператором;</li>
                                                    <li>передавать персональные данные клиента его представителям в порядке, установленном Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p style={{textAlign:"center"}}>ДОСТУП К ПЕРСОНАЛЬНЫМ ДАННЫМ</p>

                                        <ol>
                                            <li>Право доступа к персональным данным имеют:
                                                <ul>
                                                    <li>руководитель Оператора;</li>
                                                    <li>работающие с определённым клиентом работники Оператора;</li>
                                                    <li>работники бухгалтерии;</li>
                                                    <li>работники, осуществляющие техническое обеспечение деятельности Оператора.</li>
                                                </ul>
                                            </li>
                                            <li>Клиенты в целях обеспечения защиты персональных данных имеют следующие права:
                                                <ul>
                                                    <li>на полную информацию об их персональных данных и обработке этих данных;</li>
                                                    <li>на свободный бесплатный доступ к своим персональным данным, включая право на получение копий любой записи, содержащей персональные данные, за исключением случаев, предусмотренных федеральным законом;</li>
                                                    <li>на определение своих представителей для защиты своих персональных данных;</li>
                                                    <li>на требование об исключении или исправлении неверных или неполных персональных данных, а также данных, обработанных с нарушением требований Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных».</li>
                                                </ul>
                                            </li>
                                            <li>Копировать и делать выписки персональных данных разрешается исключительно в служебных целях с разрешения руководителя.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        <p style={{textAlign:"center"}}>ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ НОРМ, РЕГУЛИРУЮЩИХ ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</p>

                                        <ol>
                                            <li>Лица, виновные в нарушении порядка обращения с персональными данными, несут дисциплинарную, административную, гражданско- правовую или уголовную ответственность в соответствии с федеральными законами.</li>
                                            <li>Руководители структурных подразделений Оператора несут персональную ответственность за исполнение обязанностей их подчиненными.</li>
                                        </ol>
                                    </li>
                                </ol> */}
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>

    );
};

export default Privacy_policy;