import React from 'react';
import "../../../styles/styles.css";
import "../../../styles/dop_styles.css";
import Header from '../../header/header';
import Footer from '../../footer/footer';
const Personal_data = () => {
    return (
        <div className='wrapper'>
            <Header/>
            <main>
                <div className='block main'>
                    <div className='inner'>
                        <div className="content nositebar ">
                            <div className="breadcrumb">
                                <span><a href="/">Главная</a> / </span>
                                <span>Согласие на обработку персональных данных</span>
                            </div>
                            <h1 className="shop-title">Согласие на обработку персональных данных</h1>
                            {/* <div className="page_text">

                                <p>Пользователь, оставляя обращение, заявку на сайте http://<strong><em>(адрес сайта)</em></strong> (далее также – сайт), создавая аккаунт и/или соглашаясь с офертой на сайте, принимает настоящее Согласие на обработку персональных данных.</p>

                                <p>Пользователь, действуя свободно, своей волей и в своём интересе, подтверждая свою дееспособность, даёт своё согласие <strong><em>название компании</em></strong> (<strong><em>полные реквизиты компании</em></strong>, <strong><em>адрес компании</em></strong>) на обработку своих персональных данных как с использованием, так и без использования средств автоматизации для целей обработки входящих запросов физических лиц (пользователей) с целью консультирования, направления комментариев физическим лицам (пользователям); аналитики действий физического лица (пользователя) на сайте и функционирования сайта; выполнения обязательств по договору оферты, принятому пользователем на сайте. При этом:</p>

                                <ol>
                                    <li>Согласие предоставлено для использования моих следующих персональных данных: Фамилия, имя, отчество; номера контактных телефонов; адреса электронной почты; место работы и занимаемая должность; адрес; сведения о местоположении; тип, версия, язык операционной системы, браузера; тип устройства и разрешение его экрана; страницы, открываемые пользователем; ip-адрес.</li>
                                    <li>Обработка моих персональных данных может включать следующие действия: сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.</li>
                                </ol>

                                <p>Настоящее согласие может быть отозвано путём направления субъектом персональных данных (пользователем) или его представителем письменного заявления по адресу: <strong><em>название и физический адрес компании</em></strong> либо по адресу электронной почты: <strong><em>адрес электронной почты компании</em></strong>. В&nbsp;случае отзыва субъектом персональных данных (пользователем) согласия на обработку персональных данных <strong><em>название компании</em></strong> вправе продолжить обработку таких персональных данных в случаях, предусмотренных пунктами 2-11 части 1 статьи 6, пунктами 2-10 части 2 статьи 10, части 2 статьи 11 Федерального закона от 27.07.2006 №&nbsp;152-ФЗ «О персональных данных».</p>

                                <p>Настоящее согласие предоставляется на неопределённый срок и действует весь период обработки персональных данных. Запросы относительно персональных данных могут быть направлены по электронному адресу: <strong><em>адрес электронной почты компании</em></strong>.</p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
            <Footer />
        </div>

    );
};

export default Personal_data;