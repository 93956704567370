import React from 'react';
import cl from "./main_text.module.css";

const Main_text = () => {
    return (
        <div className={cl.main_text}>
            <p>Компания « ЕвроДом » частная региональная компания, 
                сферой деятельности которой является, оптово-розничная
                 торговля строительными и отделочными материалами. 
                 Основанная в ноябре 2008 года, компания главным 
                 приоритетом своей деятельности сделала ставку на 
                 продвижение продукции отечественных производителей, 
                 таких брендов как:  « DANELY » - межкомнатные двери, 
                 « Талма » - панели ПВХ, « Wood Home ДОМиК » - стенопотолочные панели МДФ, 
                 межкомнатные двери, «HAIRUN» - панели ПВХ и других производителей. 
                 Заключая контракты на поставку продукции от производителя минуя лишнее звено, 
                 оптовые склады и строительные рынки, компания тем самым уменьшает конечную 
                 стоимость товара для регионального потребителя. Это является 
                 немаловажным фактором выравнивания цен региона с рынками столицы.
                  Не изменяя своим принципам работать на прямую, компания сотрудничает 
                  с крупными Российскими производителями «КОМИТЕКС ЛИН» - производство 
                  линолеума, «KRONOSTAR» - производство ламината и многими другими
                   отечественными и  зарубежными производителями высококачественой 
                   строительной продукции. Вся эта продукция представлена в широком 
                   ассортименте в нашем магазине. 
            </p>
        </div>
    );
};

export default Main_text;